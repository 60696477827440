import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230710-Rate-Sheet.pdf";
const date = "10.07.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Monday 10 July 2023 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>
    <p>We have removed from sale our cashback incentive across all UK Residential Remortgage offerings. All UK Residential Remortgage rates will come with Free Standard Legal Fees included but no cashback incentive until further notice.</p>
    <p>All of our fixed rate end dates will be extended as below:</p>
    <ul>
      <li>2 year fixed rates &ndash; 31 October 2025</li>
      <li>3 year fixed rates &ndash; 31 October 2026</li>
      <li>5 year fixed rates &ndash; 31 October 2028</li>
      <li>10 year fixed rates &ndash; 31 October 2033</li>
    </ul>
    <p><strong>UK Residential Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% have increased</li>
      <li>2 Year Tracker Fee Saver at 90% and 95% LTV have decreased</li>
      <li>2 Year Tracker Standard at 80% and 90% LTV have decreased</li>
    </ul>
    <p><strong>UK Residential Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% have increased</li>
      <li>2 Year Tracker Standard at 80% and 90% LTV have decreased</li>
    </ul>
    <p><strong>UK Residential First Time Buyer Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>UK Residential Home Mover Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85%, 90% and 95% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p><strong>UK Residential Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70%, 75%, 80%, 85% and 90% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% and 80% have increased</li>
    </ul>
    <p><strong>UK Buy to Let Existing Customer Switching Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60% LTV has increased</li>
      <li>5 Year Fixed Rate Fee Saver at 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 60% LTV has increased</li>
    </ul>
    <p><strong>UK Buy to Let Existing Customer Borrowing More Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60% LTV has increased</li>
      <li>5 Year Fixed Rate Fee Saver at 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 60% LTV has increased</li>
    </ul>
    <p><strong>UK Buy to Let Purchase Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p><strong>UK Buy to Let Remortgage Range</strong></p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Fee Saver at 60%, 65% and 75% LTV have increased</li>
      <li>5 Year Fixed Rate Standard at 60%, 65% and 75% LTV have increased</li>
    </ul>
    <p><strong>International Residential Range</strong></p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Premier Exclusive at 60%, 70% and 75% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70%, 75% LTV have increased</li>
    </ul>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Monday 10 July 2023 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
